<template>
    <div class="page-warp min-page gray-page">
        <van-address-edit
            :address-info="addLooplist"
            ref="userAddressI"
            :detail-rows="4"
            :show-area="false"
            @save="onSave"
            />
    </div>
</template>
<script>
import { userAddressDetail,userAddressEdit } from "@/api/user";
import { checkPhone } from '../../assets/js/comm'
export default {
    name:'addressEdit',
    data() {
        return {
            id:'',
            addLooplist:{},
            fromPage:'',
        };
    },
    created() {
        this.id = this.$route.query.id;
        this.fromPage = this.$route.query.from;
        if(this.id) this.getUserAddressDetail();
    },
    methods: {
        //   通过id查询订单信息
        async getUserAddressDetail() {
            let res = await userAddressDetail(this.id);
            let item = res.data;
            this.addLooplist ={
                id: item.id,
                name: item.recipientName,
                tel: item.mobile,
            }
            this.$nextTick(()=>{
                this.$refs.userAddressI.setAddressDetail(item.recipientAddress)
            })
        },
        async onSave(content) {
            // if(!content.name){
            //     this.$toast.fail('姓名不能为空');
            //     return false;
            // }
            // if(!content.tel){
            //     this.$toast.fail('电话不能为空');
            //     return false;
            // }else if(checkPhone(content.tel)){
            //     this.$toast.fail('电话格式不正确');
            //     return false;
            // }
            // if(!content.addressDetail){
            //     this.$toast.fail('详细地址不能为空');
            //     return false;
            // }
            let where = {
                id:this.id,
                recipientName:content.name,
                recipientPhone:content.tel,
                recipientAddress:content.addressDetail,
            }
            let res = await userAddressEdit(where);

            this.$toast.success('修改成功');
            this.$router.push({
                path:'/addressList',
                query:{
                    from:this.fromPage
                }
            })
        },
    },
};
</script>
<style lang="less" scope>
</style>